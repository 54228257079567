<template>
  <div>
    <Card>
      <template #header>
        <h4 class="card-header-title">General</h4>
      </template>
      <CustomSwitch
        :value="manualFallbackCallToAction.enabled"
        label="Manual Fallback Call To Action"
        @input="onManualFallbackCallToActionChange"
      >
        <template #helperText>
          Enabling this will present a button on the Zero Search Results for Payroll screen and
          Authentication Error screen. This button will emit the <code>onClose</code> SDK event with
          data containing <code>reason</code>: <code>manual-fallback</code>.
        </template>
      </CustomSwitch>
      <CustomSwitch
        :value="testCredentials"
        :label="getProductionOnlyFeatureLabel('Test Credentials')"
        @input="onTestCredentialsChange"
        :disabled="isSandbox"
      >
        <template #helperText>
          Disabling test credentials will prevent the usage of
          <a href="https://docs.atomicfi.com/reference/transact-sdk#testing" target="_blank">
            test credentials outlined in the documentation.
          </a>
          <div v-if="isSandbox" class="small text-info mt-2">
            *This feature is only available in production. Test credentials are required in sandbox.
          </div>
        </template>
      </CustomSwitch>
    </Card>
    <Card v-if="isProduction">
      <template #header>
        <h4 class="card-header-title">Conversion Optimization</h4>
      </template>
      <p class="text-muted">
        <small>
          To improve the Atomic experience and increase conversion rate, our team periodically runs
          experiments and surveys to make data driven decisions around modifications. Please opt in
          to help us make our product better for our shared customers.
        </small>
      </p>
      <p class="text-muted">
        <small>
          Controls for experiments and surveys have moved:
          <ul>
            <li>
              <router-link :to="{ name: 'settings-experiments' }">Experiments</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'settings-surveys' }">Surveys</router-link>
            </li>
          </ul>
        </small>
      </p>
    </Card>
    <Card>
      <template #header>
        <h4 class="card-header-title">Deposit</h4>
      </template>
      <FractionalDepositFeatureSettings
        :data="fractionalDepositSettings"
        :fixedFractionalDepositLimitSettings="fixedFractionalDepositLimitSettings"
        :percentFractionalDepositLimitSettings="percentFractionalDepositLimitSettings"
        @input="onFractionalDepositChange"
      />
      <CustomSwitch
        :value="manualDepositInstructions"
        helperText="Allows your users
        to view instructions to manually switch their direct deposit."
        label="Manual Instructions"
        @input="onManualDepositInstructionsChange"
      />
      <CustomSwitch
        :value="showTaskHistory"
        helperText="Displays the status of recent direct deposit activity performed by the user."
        label="Show Task History"
        @input="onShowTaskHistoryChange"
      />
    </Card>
    <Card>
      <template #header>
        <h4 class="card-header-title">Bounded Fractional Deposit</h4>
      </template>
      <SettingsBoundedFractionalDeposit
        :data="{
          percent: percentFractionalDepositLimitSettings,
          fixed: fixedFractionalDepositLimitSettings,
        }"
        :enabled="fractionalDepositsEnabled"
        @fixedInput="onBoundedFractionalDepositChangeFixed"
        @percentInput="onBoundedFractionalDepositChangePercent"
      />
    </Card>
    <Card v-if="userLinkContinuousAccess && userLinkContinuousAccess.enabled">
      <template #header>
        <h4 class="card-header-title">Continuous Access</h4>
      </template>
      <SettingsFeaturesUserLinkMonitoring
        :userLinkContinuousAccess="userLinkContinuousAccess"
        @input="onUserLinkChange($event)"
      />
    </Card>
    <Card v-if="hasScope(SCOPES.PAY_LINK)">
      <template #header>
        <h4 class="card-header-title">PayLink</h4>
      </template>
      <SettingsFeaturesPayLink :data="payLink" @input="onPayLinkChange($event)" />
    </Card>
    <Card :bodyHasPadding="false">
      <template #header>
        <h4 class="card-header-title">
          Verify
          <small class="text-muted d-block pt-2">
            Select desired fields. Fields marked with an * are required fields and will be included
            in every verify task.
          </small>
        </h4>
      </template>
      <ul class="list-group">
        <li
          v-for="(groupDefinition, groupKey) in verifyFields"
          class="list-group-item"
          :key="groupKey"
        >
          <h4 v-b-toggle="`collapse-${groupKey}`">
            <ChevronRightIcon size="1.2x" class="toggle-icon"></ChevronRightIcon>
            {{ groupDefinition.label }}
          </h4>
          <b-collapse :id="`collapse-${groupKey}`">
            <FieldSelectionForm
              :fieldDefinitions="groupDefinition.fields"
              :value="outputsFieldSettings"
              @input="onFieldSelectionChange"
              :disableAll="isUpdating"
            />
          </b-collapse>
        </li>
      </ul>
    </Card>
  </div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons'
import { DISTRIBUTION_LIMIT_TYPES } from '@/utils/constants'
import { getCustomerUpdater } from '@/lib/get-customer-updater'
import { hasScope } from '@/lib/authorization'
import { mapGetters } from 'vuex'
import { SCOPES } from '@atomicfi/constants-shared'
import Card from '@/components/MainContent/Card'
import CustomSwitch from '@/components/MainContent/CustomSwitch'
import FieldSelectionForm from '@/components/MainContent/FieldSelectionForm'
import FractionalDepositFeatureSettings from '@/components/MainContent/FractionalDepositFeatureSettings'
import SettingsBase from './SettingsBase'
import SettingsBoundedFractionalDeposit from '@/components/Modules/Settings/FractionalDeposit/SettingsBoundedFractionalDeposit'
import SettingsFeaturesUserLinkMonitoring from '@/components/Modules/Settings/SettingsFeaturesUserLinkMonitoring'
import SettingsFeaturesPayLink from '@/components/Modules/Settings/SettingsFeaturesPayLink'

const verifyFields = {
  incomeFields: {
    label: 'Income',
    fields: [
      {
        key: 'income',
      },
      {
        key: 'incomeType',
      },
      {
        key: 'annualIncome',
      },
      {
        key: 'hourlyIncome',
      },
      { key: 'payCycle' },
      { key: 'nextExpectedPayDate' },
      { key: 'currentPayPeriodStart' },
      { key: 'currentPayPeriodEnd' },
      { key: 'unpaidHoursInPayPeriod' },
      { key: 'netHourlyRate' },
    ],
  },
  employmentFields: {
    label: 'Employment',
    fields: [
      { key: 'employeeType' },
      { key: 'employmentStatus' },
      { key: 'jobTitle' },
      { key: 'startDate' },
      { key: 'minimumMonthsOfEmployment' },
      { key: 'weeklyHours' },
      {
        key: 'employer',
        fields: [
          { key: 'name', label: 'Employer Name' },
          {
            key: 'address',
            label: 'Employer Address',
            fields: [
              { key: 'line1', label: 'Address Line 1' },
              { key: 'line2', label: 'Address Line 2' },
              { key: 'city' },
              { key: 'state' },
              { key: 'postalCode' },
              { key: 'country' },
            ],
          },
        ],
      },
    ],
  },
  identityFields: {
    label: 'Identity',
    fields: [
      { key: 'firstName' },
      { key: 'lastName' },
      { key: 'dateOfBirth' },
      { key: 'email' },
      { key: 'phone' },
      { key: 'ssn', label: 'Social Security Number' },
      { key: 'address' },
      { key: 'city' },
      { key: 'state' },
      { key: 'postalCode' },
    ],
  },
  statementFields: {
    label: 'Statements',
    fields: [
      {
        key: 'statements',
        fields: [
          { key: 'date' },
          { key: 'payPeriodStartDate' },
          { key: 'payPeriodEndDate' },
          { key: 'hours' },
          { key: 'grossAmount' },
          { key: 'ytdGrossAmount', label: 'YTD Gross Amount' },
          { key: 'netAmount' },
          { key: 'ytdNetAmount', label: 'YTD Net Amount' },
          { key: 'paymentMethod' },
          { key: 'paystub', label: 'Paystub PDF' },
          {
            key: 'earnings',
            fields: [
              { key: 'rawLabel', disabled: true },
              { key: 'amount', disabled: true },
              { key: 'ytdAmount', label: 'YTD Amount', disabled: true },
              { key: 'category', disabled: true },
              { key: 'hours', disabled: true },
              { key: 'rate', disabled: true },
            ],
          },
          {
            key: 'deductions',
            fields: [
              { key: 'rawLabel', disabled: true },
              { key: 'label', disabled: true },
              { key: 'amount', disabled: true },
              { key: 'ytdAmount', label: 'YTD Amount', disabled: true },
              { key: 'category', disabled: true },
            ],
          },
          {
            key: 'netAmountAdjustments',
            fields: [
              { key: 'label', disabled: true },
              { key: 'amount', disabled: true },
            ],
          },
        ],
      },
    ],
  },
  depositAccountFields: {
    label: 'Deposit Accounts',
    fields: [
      {
        key: 'accounts',
        fields: [
          { key: 'routingNumber' },
          { key: 'accountNumber' },
          { key: 'type' },
          { key: 'bankName' },
          { key: 'distributionType' },
          { key: 'distributionAmount' },
        ],
      },
    ],
  },
  taxFields: {
    label: 'Taxes',
    fields: [
      {
        key: 'w2s',
        label: 'W-2s',
        fields: [
          { key: 'year' },
          { key: 'totalWages' },
          { key: 'form', label: 'W2 Form' },
          { key: 'parsedData', label: 'Parsed Data' },
        ],
      },
      {
        key: '1099s',
        label: '1099s',
        fields: [{ key: 'year' }, { key: 'totalWages' }, { key: 'form', label: '1099 Form' }],
      },
    ],
  },
  timesheetFields: {
    label: 'Timesheets',
    fields: [
      {
        key: 'timesheets',
        fields: [
          { key: 'type', disabled: true },
          { key: 'duration', disabled: true },
          { key: 'date', disabled: true },
          { key: 'clockedIn', disabled: true },
          { key: 'clockedOut', disabled: true },
        ],
      },
    ],
  },
}

export default {
  name: 'SettingsFeatures',
  extends: SettingsBase,
  components: {
    Card,
    ChevronRightIcon,
    CustomSwitch,
    FieldSelectionForm,
    FractionalDepositFeatureSettings,
    SettingsBoundedFractionalDeposit,
    SettingsFeaturesUserLinkMonitoring,
    SettingsFeaturesPayLink,
  },
  data: () => ({
    isUpdating: false,
    verifyFields,
    fractionalDeposits: {},
    fractionalDepositLimits: {},
    fractionalFixedDepositLimits: {},
    SCOPES,
  }),
  computed: {
    ...mapGetters('environment', ['isProduction', 'isSandbox']),
    statementsDesiredTimePeriod: {
      get() {
        return this.activeCustomer.features.statementsDesiredTimePeriod
      },
      set(newValue) {
        return newValue
      },
    },
    fractionalDepositsEnabled() {
      return this.activeCustomer.features.fractionalDeposits?.enabled
    },
    fractionalDepositSettings() {
      return this.activeCustomer.features.fractionalDeposits
    },
    fixedFractionalDepositLimitSettings() {
      return (
        this.activeCustomer.features.fractionalDeposits?.distribution?.fixedLimits || {
          minimum: { amount: null, type: DISTRIBUTION_LIMIT_TYPES.WARN },
          maximum: { amount: null, type: DISTRIBUTION_LIMIT_TYPES.WARN },
        }
      )
    },
    percentFractionalDepositLimitSettings() {
      return (
        this.activeCustomer.features.fractionalDeposits?.distribution?.percentLimits || {
          minimum: { amount: null, type: DISTRIBUTION_LIMIT_TYPES.WARN },
          maximum: { amount: null, type: DISTRIBUTION_LIMIT_TYPES.WARN },
        }
      )
    },
    manualDepositInstructions() {
      return this.activeCustomer.features.manualDepositInstructions
    },
    manualFallbackCallToAction() {
      return this.activeCustomer.features.manualFallbackCallToAction
    },
    outputsFieldSettings() {
      return this.activeCustomer.outputsFieldSettings
    },
    userLinkContinuousAccess() {
      return this.activeCustomer.features.userLinkContinuousAccess
    },
    payLink: {
      get() {
        return this.activeCustomer.features.payLink
      },
      set(newValue) {
        return newValue
      },
    },
    showTaskHistory() {
      return this.activeCustomer.features.showTaskHistory
    },
    testCredentials() {
      return this.activeCustomer.features.testCredentials?.enabled
    },
  },
  methods: {
    hasScope,

    getProductionOnlyFeatureLabel(label) {
      return `${label}${this.isSandbox ? '*' : ''}`
    },

    async onUserLinkChange({ key, value }) {
      const customerUpdate = getCustomerUpdater({
        getUpdates: (value) => ({
          [`features.userLinkContinuousAccess.${key}`]: value,
        }),
        localStateKey: `userLinkContinuousAccess.${key}`,
      }).bind(this)

      await customerUpdate(value)
    },

    onPayLinkChange: getCustomerUpdater({
      getUpdates: (payLink) => ({
        'features.payLink': payLink,
      }),
      localStateKey: 'payLink',
    }),

    onStatementsDesiredTimePeriodChanged: getCustomerUpdater({
      getUpdates: (newTimePeriod) => ({
        'features.statementsDesiredTimePeriod': newTimePeriod,
      }),
      localStateKey: 'statementsDesiredTimePeriod',
    }),

    onFractionalDepositChange: getCustomerUpdater({
      getUpdates: (fractionalDeposits) => ({
        'features.fractionalDeposits': fractionalDeposits,
      }),
      localStateKey: 'fractionalDeposits',
    }),

    onBoundedFractionalDepositChangeFixed: getCustomerUpdater({
      getUpdates: (fractionalDepositLimits) => {
        return { 'features.fractionalDeposits.distribution.fixedLimits': fractionalDepositLimits }
      },
      localStateKey: 'fractionalDepositLimits',
    }),

    onBoundedFractionalDepositChangePercent: getCustomerUpdater({
      getUpdates: (fractionalDepositLimits) => {
        return { 'features.fractionalDeposits.distribution.percentLimits': fractionalDepositLimits }
      },
      localStateKey: 'fractionalDepositLimits',
    }),

    onManualDepositInstructionsChange: getCustomerUpdater({
      getUpdates: (manualDepositInstructions) => ({
        'features.manualDepositInstructions': manualDepositInstructions,
      }),
      localStateKey: 'manualDepositInstructions',
    }),

    onShowTaskHistoryChange: getCustomerUpdater({
      getUpdates: (showTaskHistory) => ({
        'features.showTaskHistory': showTaskHistory,
      }),
      localStateKey: 'showTaskHistory',
    }),

    onTestCredentialsChange: getCustomerUpdater({
      getUpdates: (enabled) => ({
        'features.testCredentials.enabled': enabled,
      }),
      localStateKey: 'testCredentials',
    }),

    onManualFallbackCallToActionChange: getCustomerUpdater({
      getUpdates: (enabled) => ({
        'features.manualFallbackCallToAction.enabled': enabled,
      }),
      localStateKey: 'manualFallbackCallToAction',
    }),

    onFieldSelectionChange: getCustomerUpdater({
      getUpdates: (fieldSelection) => ({
        outputsFieldSettings: fieldSelection,
      }),
      localStateKey: 'outputsFieldSettings',
    }),

    onSetPrimaryDistributionType: getCustomerUpdater({
      getUpdates: () => ({}),
    }),
  },
}
</script>

<style scoped lang="scss">
.list-group-item > h4 {
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 0;
}
.toggle-icon {
  transition: 300ms transform ease-in-out;
}
.not-collapsed > .toggle-icon {
  transform: rotate(90deg);
}
</style>
