import { Product } from '@atomicfidev/transact-javascript'
import {
  CheckCircleIcon,
  ClockIcon,
  DollarSignIcon,
  InfoIcon,
  LoaderIcon,
  MoonIcon,
  PieChartIcon,
  SearchIcon,
  SunIcon,
  XCircleIcon,
} from 'vue-feather-icons'
import { EMULATOR_CUSTOMIZATION_TABS } from '@/utils/constants'
import { SCOPES } from '@atomicfi/constants-shared'

export default {
  namespaced: true,
  state: {
    activeNavItem: 'initialization',
    activeTabIndex: 0,
    settings: {
      demo: {
        enabled: true,
        customer: {
          name: '',
          branding: {
            logo: '',
          },
        },
      },
      scope: undefined,
      tasks: [],
      deeplink: { step: 'search-company' },
      language: 'en',
      theme: {
        dark: false,
        display: 'inline',
        overlayColor: 'rgb(144, 144, 144, 0.65)',
      },
    },
    languages: [
      {
        flag: '🇺🇸',
        code: 'en',
        title: 'English',
      },
      {
        flag: '🇪🇸',
        code: 'es',
        title: 'Español',
      },
    ],
    useMultipleAccounts: false,
    useUplinkDemo: false,
    colorModes: [
      {
        name: 'Light Mode',
        icon: SunIcon,
        isDark: false,
      },
      {
        name: 'Dark Mode',
        icon: MoonIcon,
        isDark: true,
      },
    ],
    loginInputType: '',
    supportedFonts: [],
    fontsToCustomize: [
      {
        label: 'Body',
        namespace: 'theme',
        path: 'fonts.body.fontFamily',
      },
      {
        label: 'Buttons',
        namespace: 'theme',
        path: 'fonts.buttons.fontFamily',
      },
      {
        label: 'Headings',
        namespace: 'theme',
        path: 'fonts.headings.fontFamily',
      },
    ],
    products: [Product.DEPOSIT],
    scope: undefined,
    deeplink: {
      step: 'search-company',
      companyName: undefined,
      companyId: undefined,
      connectorId: undefined,
    },
    taxForms: ['w2s'],
    pendingMutations: [],
    session: undefined,
    fullscreenMode: false,
  },
  getters: {
    isInitialized: (state) => !!state.session,
    settings: (state) => state.settings,
    screensToCustomize: (state) => {
      return [
        {
          name: 'consent',
          title: 'Consent Screen',
          icon: InfoIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Security info button text',
                  phraseNamespace: 'welcome',
                  phraseKey: 'protectYouButtonText',
                },
                {
                  label: 'Permission granted caption',
                  phraseNamespace: 'welcome',
                  phraseKey: 'hasAccessTitle',
                },
                {
                  label: 'Permission restricted caption',
                  phraseNamespace: 'welcome',
                  phraseKey: 'noAccessTitle',
                },
              ],
            },
          ],
        },
        {
          name: 'search',
          title: 'Search Screen',
          icon: SearchIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Main Title',
                  phraseNamespace: 'searchCompany',
                  phraseKey: 'title',
                },
                {
                  label: 'Search input placeholder',
                  phraseNamespace: 'searchCompany',
                  phraseKey: 'inputPlaceholder',
                },
                {
                  label: 'Manual fallback call to action',
                  phraseNamespace: 'shared',
                  phraseKey: 'manualFallbackCta',
                },
              ],
            },
          ],
        },
        {
          name: 'distribution-confirmation',
          title: 'Distribution Confirmation Screen',
          icon: PieChartIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Distribution confirmation title',
                  phraseNamespace: 'distributionConfirmation',
                  phraseKey: 'confirm',
                },
                {
                  label: 'Change button text',
                  phraseNamespace: 'distributionConfirmation',
                  phraseKey: 'change',
                },
              ],
            },
          ],
        },
        {
          name: 'distribution-setup',
          title: 'Distribution Setup Screen',
          icon: DollarSignIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Main title',
                  phraseNamespace: 'depositOptions',
                  phraseKey: 'title',
                },
                {
                  label: 'Warning when below minimum distribution - Fixed Amount',
                  phraseNamespace: 'distribution',
                  phraseKey: 'underMinimumFractionalDistributionFixed',
                },
                {
                  label: 'Warning when above maximum distribution - Fixed Amount',
                  phraseNamespace: 'distribution',
                  phraseKey: 'overMaximumFractionalDistributionFixed',
                },
                {
                  label: 'Warning when below minimum distribution - Percentage',
                  phraseNamespace: 'distribution',
                  phraseKey: 'underMinimumFractionalDistributionPercent',
                },
                {
                  label: 'Warning when above maximum distribution - Percentage',
                  phraseNamespace: 'distribution',
                  phraseKey: 'overMaximumFractionalDistributionPercent',
                },
              ],
            },
          ],
        },
        {
          name: 'progress',
          title: 'Progress',
          icon: LoaderIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Progress Title - Deposit',
                  phraseNamespace: 'taskProgress',
                  phraseKey: 'progressTitleDeposit',
                },
                {
                  label: 'Progress Title - Verify',
                  phraseNamespace: 'taskProgress',
                  phraseKey: 'progressTitleVerify',
                },
              ],
            },
          ],
        },
        {
          name: 'exit-confirmation',
          title: 'Exit Confirmation',
          icon: XCircleIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Main title',
                  phraseNamespace: 'exitModal',
                  phraseKey: 'appExit',
                },
                {
                  label: 'Subtext',
                  phraseNamespace: 'exitModal',
                  phraseKey: 'appProgessLost',
                },
                {
                  label: 'Continue button text',
                  phraseNamespace: 'exitModal',
                  phraseKey: 'cancelExit',
                },
                {
                  label: 'Confirm exit button text',
                  phraseNamespace: 'exitModal',
                  phraseKey: 'yesExit',
                },
              ],
            },
          ],
        },
        {
          name: 'high-latency',
          title: 'High Latency Screen',
          icon: ClockIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'High latency title',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageLong',
                },
                {
                  label: 'High latency description',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageWait',
                },
              ],
            },
          ],
        },
        {
          name: 'success',
          title: 'Success Screen',
          icon: CheckCircleIcon,
          scopes: [SCOPES.USER_LINK],
          customizations: [
            {
              tab: EMULATOR_CUSTOMIZATION_TABS.PHRASES,
              phrases: [
                {
                  label: 'Pay cycle expectation text (deposit)',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageDistributionUpdate',
                },
                {
                  label: 'Waiting is optional text',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageWait',
                },
                {
                  label: 'Post-auth delay title',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageOnlyAuthCompletedTitle',
                },
                {
                  label: 'Post-auth delay description (deposit)',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageOnlyAuthCompletedDeposit',
                },
                {
                  label: 'Post-auth delay description (verify)',
                  phraseNamespace: 'taskCompleted',
                  phraseKey: 'messageOnlyAuthCompletedVerify',
                },
              ],
            },
          ],
        },
      ].filter((screen) => screen.scopes.includes(state.scope?.name))
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
      if (!products.includes(Product.DEPOSIT)) {
        state.useMultipleAccounts = false
      }
    },
    setTaxForms(state, taxForm) {
      state.taxForms = taxForm
    },
    setScope(state, scope) {
      state.scope = scope
    },
    setDemoCustomerLogo(state, params) {
      const { logo } = params
      state.settings.demo.customer.branding.logo = logo
    },
    setActiveNavItem(state, activeNavItem) {
      state.activeNavItem = activeNavItem
    },
    addOrUpdateMutation(state, mutation) {
      const index = state.pendingMutations
        .map((mutationItem) => mutationItem.id)
        .indexOf(mutation.id)
      if (index === -1) {
        state.pendingMutations.push(mutation)
      } else {
        if (mutation.value === mutation.valueBeforeChanges) {
          state.pendingMutations.splice(index, 1)
        } else {
          state.pendingMutations[index] = mutation
        }
      }
    },
    resetPendingMutations(state) {
      state.pendingMutations = []
    },
    removeMutation(state, id) {
      const index = state.pendingMutations.map((mutation) => mutation.id).indexOf(id)
      state.pendingMutations.splice(index, 1)
    },
    setSession(state, session) {
      state.session = session
    },
    setLanguage(state, language) {
      state.settings.language = language
    },
    setUseMultipleAccounts(state, value) {
      state.useMultipleAccounts = value
    },
    setUseUplinkDemo(state, value) {
      state.useUplinkDemo = value
    },
    setSupportedFonts(state, fontList) {
      state.supportedFonts = fontList
    },
    setLoginInputType(state, inputType) {
      state.loginInputType = inputType
    },
    setActiveTabIndex(state, index) {
      state.activeTabIndex = index
    },
    setDarkMode(state, darkMode) {
      state.settings.theme.dark = darkMode
    },
    setFullscreenMode(state, fullscreenMode) {
      state.fullscreenMode = fullscreenMode
    },
  },
  actions: {
    updateDarkMode({ commit }, { analytics, darkMode }) {
      commit('setDarkMode', darkMode)

      analytics.track({
        event: 'Toggle Emulator Dark Mode',
        payload: {
          darkMode,
        },
      })
    },
    updateLanguage({ commit }, { analytics, language }) {
      commit('setLanguage', language)

      analytics.track({
        event: 'Set Emulator Language',
        payload: {
          language,
        },
      })
    },
    updateSupportedFonts({ commit }, fontList) {
      commit('setSupportedFonts', fontList)
    },
    updateLoginInputType({ commit }, inputType) {
      commit('setLoginInputType', inputType)
    },
    resetProducts({ commit }) {
      commit('setProducts', [Product.DEPOSIT])
    },
    updateTaxFormType({ commit }, taxForm) {
      commit('setTaxForms', taxForm)
    },
  },
}
